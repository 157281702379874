<template>
  <div>
    <!--
    <titleBar
      v-show="!isSettingsHome"
      :title="titleBarTitle"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      
    />  -->

    <titleBar
      v-if="isSettingsHome"
      :title="'Billing'"
      :img="user?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <settingsTabs />

    <div class="section" :class="{ isSettingsHome: isSettingsHome, notHome: !isSettingsHome }">
      <div class="container w1200" v-if="user">
        <h2 class="title is-2">Payouts</h2>

        TBD
        <div class="columns is-variable is-8">
          <div class="column is-narrow zone list">
            <jsonDebug :data="user" label="user" style="max-width: 500px" />

            <div class="intro-card" style="text-align: center">
              <div class="message-avatar">
                <img :src="user?.avatar" alt="" class="avatar" />
              </div>

              <div class="message-handle">
                <h2 class="title is-6" style="margin-bottom: 4px">{{ user?.handle }} Settings</h2>

                <router-link :to="`/u/${user.handle}`" class="button is-light is-small is-rounded is-hidden-touch">
                  View Profile
                </router-link>
              </div>
            </div>

            <!--        MENU! Loop over menu, router links buttons -->

            <br />
            <div class="menuSettings">
              <div v-for="i in menu" :key="i.to">
                <router-link :to="i.to" class="button is-outlined is-dark is-fullwidth" style="margin-bottom: 10px">
                  {{ i.label }}
                </router-link>
              </div>
            </div>

            <div class="is-hidden-touch">
              <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
                >Save changes</b-button
              >
              <b-button type="is-danger is-outlined" @click="resetChanges" :loading="posting" :disabled="posting"
                >Cancel changes</b-button
              >
            </div>
          </div>
          <div class="column zone convo">
            <router-view :user="user" :handle="handle" v-if="user" @save="save"></router-view>

            <!--
            <empty
              v-if="$route.name == 'BotSettings'"
              title="Adjust your bot settings here"
              btTo=""
              img="https://mir-s3-cdn-cf.behance.net/project_modules/disp_still/9f6465159728551.63a456b253488.gif"
              btLabel="Explore new AIs"
            /> -->
          </div>
        </div>
      </div>

      <!--
    <v-row no-gutters>
      <v-col v-if="!isMobile || isSettingsHome" cols="3">
        <v-list>
          <v-list-item
            v-for="(interlocutor, index) in interlocutors"
            :key="index"
            :to="{ name: 'inbox-thread', params: { conversationId: interlocutor.conversationId } }"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ interlocutor.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ interlocutor.lastMessage }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col v-if="!isSettingsHome || (isMobile && interlocutors.length === 0)" cols="12" sm="9">
        <router-view></router-view>
      </v-col>
    </v-row> -->
    </div>
  </div>
</template>

<script>
import JsonDebug from "@/components/e/jsonDebug.vue";
import inboxList from "@/components/inbox/InboxList.vue";
import inboxThread from "@/components/inbox/inboxThread.vue";

import settingsTabs from "@/components/nav/settingsTabs.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  name: "BotSettings",
  components: {
    settingsTabs,
    // inboxList,
    //inboxThread,
    //  JsonDebugt,
  },
  data() {
    return {
      // featuredTags,
      interlocutors: [],
      convos: [],
      convo: null,
      tasks: [],
      user: null,
      //keyFeatures,
      // projs: [],
      loading: true,
      posting: false,
      // loadingOps: true,
    };
  },
  mounted() {
    this.loadProfile();
    /*window.API.getFeaturedBots().then((data) => {
      this.interlocutors = data;
      this.loading = true;
      this.loadThread(this.$route.params.userId, this.$route.params.convo);
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
  watch: {
    $route(to, from) {
      //  this.loadThread(to.params.userId, to.params.convo);
    },
  },
  methods: {
    loadProfile(index) {
      var id = this.handle; //this.$route.params.userId;

      var opt = {};
      // if is admin, add moderator option.
      /*
      if (this.$store.main.getters.isAdmin) {
        opt = { moderator: true };
      }*/

      var isAdmin = this.$store.main.getters.isAdmin;
      var method = isAdmin ? "userProfileAdmin" : "userProfileOwner";
      window.API[method](id, opt).then((b) => {
        this.user = b;
        this.loading = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },
    resetChanges() {
      this.loadProfile();
    },
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.user;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };

      let method;
      //ad is admin
      if (this.$route.name == "UserSettingsAdmin" && this.$store.main.getters.isAdmin) {
        method = "updateUserAdmin"; //PUT, for admins only
      } else {
        method = "updateUser";
      }

      window.API[method](d.handle, d).then((bot) => {
        this.posting = false;
        //snackbar black buefy
        this.$buefy.snackbar.open({
          message: "Changes saved",
          type: "is-success",
          position: "is-bottom-right",
          actionText: "Close",
          indefinite: false,
        });

        //bot updated!
      });
    },
  },
  computed: {
    menu() {
      var h = this.handle; //this.$route.params.userId;
      const base = "/u/" + h + "/settings/";
      var menu = [
        {
          label: "Edit Profile",
          icon: "fal fa-pencil",
          to: base + "profile",
        },

        {
          label: "Context info",
          icon: "fal fa-analytics",
          to: base + "context",
        },

        {
          label: "Change password",
          icon: "fal fa-key",
          to: base + "pass",
        },

        {
          label: "Create a bot",
          icon: "fal fa-analytics",
          to: "/creators",
          //  to: base + "create",
        },

        /*


        {
          label: "Monetization",
          icon: "fal fa-lock-open",
          to: base + "monetization",
        },

        {
          label: "My bots",
          icon: "fal fa-robot",
          to: base + "messaging",
        },




        {
          label: "Gallery",
          icon: "fal fa-photo",
          to: base + "gallery",
        },

        //
        {
          label: "Edit Readme",
          icon: "fal fa-file",
          to: base + "readme",
        },
*/
        {
          label: "Mod / Admin",
          icon: "fal fa-wrench",
          to: base + "admin",
          isAdmin: true,
        },
      ];
      // filter out admin only items
      menu = menu.filter((m) => {
        if (m.isAdmin && !this.$store.main.getters.isAdmin) return false;
        return true;
      });

      return menu;
    },
    isSettingsHome() {
      return (
        this.$route.name === "UserSettings"
        //this.$route.name === "BotSettingsHome" ||
        //this.$route.name === "BotSettingsEmpty"
      );
    },
    isNewMessage() {
      return this.$route.name === "inboxNewConvo" || this.$route.name === "inboxNewConvoTask";
    },
    interlocutorsShown() {
      //filter and add some props like "active., typing, etc..."
      return this.interlocutors;
      // Return a list of interlocutors here based on some state or props
    },

    newRoute() {
      return this.isNewMessage;
    },
    isMobile() {
      return true;
      // Return a boolean indicating whether the user is on a mobile device
    },
    handle() {
      return this.$route.params.userId || this.user?.handle || this.$store.main.getters.userId;
    },
    titleBarTitle() {
      if (this.isSettingsHome) return "Chats";
      // if (this.isNewMessage) return this.$route.params.userId + " ✨ ";
      var rb = this.handle;
      if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
      // if (this.user) return this.user.handle;
      ///var rb = this.$route.params.userId;
    },
    title() {
      return this.titleBarTitle + " / DM";
    },
  },
};
</script>

<style scoped>
.zone {
  /* 
  border-radius: 50px;
  background-color: white;
  border: 1px #ccc solid;*/
}

.zone.list {
  /*
  border-radius: 50px;
   background-color: white;
  */
}

/* mobiel selective columns depending on class isSettingsHome using media query*/

.inboxList {
  max-width: 300px;
}
@media only screen and (max-width: 1022px) {
  .isSettingsHome .zone.convo {
    display: none;
  }
  .notHome .zone.list {
    display: none;
  }
  .inboxList {
    max-width: 999px;
  }
}

img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}
</style>
